import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import styles from '../Styles/Song.module.css';

function SongSkeleton() {
  return (
    <SkeletonTheme baseColor="#333333" highlightColor="#444">
      <div className={styles.song}>
        <section className={styles.leftSide}>
          <div className={styles.cover}>
            <Skeleton height={70} width={70} />
          </div>
          <div className={styles.songInfo}>
            <h4 className={styles.songName}><Skeleton count={1} width={150} /></h4>
            <p className={styles.artistName}><Skeleton count={1} width={100} /></p>
          </div>
        </section>
        <section className={styles.middle}>
          <p className={styles.albumName}><Skeleton count={1} width={140} /></p>
        </section>
        <section className={styles.addedDate}><Skeleton count={1} width={80} /></section>
      </div>
    </SkeletonTheme>
  )
}

export default SongSkeleton