import React from 'react';

import { useLocation, Link } from "react-router-dom"
import { useSelector } from "react-redux";

import { RootState } from "../Store";
import {
  AiFillHome,
  SlPlaylist,
  BiSearchAlt,
} from "../Config/icons";

import styles from "../Styles/Sidebar.module.css";

function Sidebar() {
  const location = useLocation().pathname;
  const songs: any = useSelector((state: RootState) => state.playlist.songs);

  const links = [
    {
      name: "Home",
      path: "/",
      icon: <AiFillHome />
    },
    {
      name: "Playlists",
      path: "/playlists",
      icon: <SlPlaylist />
    },
    {
      name: "Search",
      path: "/search",
      icon: <BiSearchAlt />
    }
  ];

  return (
    <nav className={`${styles.sidebar} ${songs ? styles.songPlayed : ''}`}>
      <header>
        <h1><Link to="/">MyZent</Link></h1>
      </header>
      <ul>
        {links.map((link, idx) => (
          <Link key={idx} to={link.path}>
            <li className={link.path === location ? styles.active : ''}>
              <span className={styles.icon}>{link.icon}</span>
              <p className={styles.text}>{link.name}</p>
            </li>
          </Link>
        ))}
      </ul>
    </nav>
  );
}

export default Sidebar;
