import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import { RootState } from '../Store';
import { setAd, setSong } from '../Store/songSlice';

import styles from '../Styles/Song.module.css';

interface Props {
  id: string;
  cover: string;
  name: string;
  artist: string;
  album: string;
  added_date: string;
}

function Song({ id, cover, name, artist, album, added_date }: Props) {
  const dispatch = useDispatch();
  const token: any = useSelector((state: RootState) => state.auth.token);

  const play = async () => {
    const { data } = await axios.get(`/songs/${id}`, {
      headers: {
        Authorization: token
      }
    });

    const { ad, song } = data;

    dispatch(setAd({ ad }));
    dispatch(setSong({
      cover: song.cover,
      name: song.name,
      artistName: song.artistName,
      song: song.song
    }));
  }

  return (
    <div className={styles.song}>
      <section className={styles.leftSide}>
        <div className={styles.cover}>
          <img src={cover} alt={name} />
        </div>
        <div className={styles.songInfo}>
          <h4 className={styles.songName}>{name}</h4>
          <p className={styles.artistName}>{artist}</p>
        </div>
      </section>
      <section className={styles.middle}>
        <p className={styles.albumName}>{album}</p>
      </section>
      <section className={styles.addedDate}>{added_date}</section>
    </div>
  )
}

export default Song