import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SongState {
  ad: any;
  cover: string | null;
  name: string;
  artistName: string;
  song: string | null;
}

// Initialize state from localStorage or use default state
const initialState: SongState = {
  ad: null,
  cover: null,
  name: 'Song Name',
  artistName: 'Artist Name',
  song: null
};

export const songSlice = createSlice({
  name: 'song',
  initialState,
  reducers: {
    setAd: (state, action: PayloadAction<{ ad: any }>) => {
      state.ad = action.payload.ad;
    },
    setSong: (state, action: PayloadAction<{ cover: string, name: string, artistName: string, song: string }>) => {
      const payload = action.payload;
      state.cover = payload.cover;
      state.name = payload.name;
      state.artistName = payload.artistName;
      state.song = payload.song;
    }
  },
});

export const { setAd, setSong } = songSlice.actions;

export default songSlice.reducer;
