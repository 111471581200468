import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import authReducer from './authSlice'
import songReducer from './songSlice'
import playlistReducer from './playlistSlice'

const reducer = combineReducers({
  auth: authReducer,
  song: songReducer,
  playlist: playlistReducer,
})

const store = configureStore({
  reducer,
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;