import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url: string, token: string) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(url, {
          headers: { Authorization: token }
        });
        setData(response.data);
        setError(null);
      } catch (error: any) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, token]);  // Added token to the dependency array

  return { data, error, loading };
};

export default useFetch;
