import { useSelector } from 'react-redux';

import MainLayout from '../Layouts/MainLayout'
import PlaylistContainer from '../Components/PlaylistContainer'
import PlaylistCard from '../Components/PlaylistCard'
import useFetch from '../Hooks/useFetch';
import { RootState } from '../Store';
import PlaylistCardSkeleton from '../Components/PlaylistCardSkeleton';

interface Playlist {
  cover: string;
  name: string;
  slug: string;
}

function Playlists() {
  const token: any = useSelector((state: RootState) => state.auth.token);
  const { data, error, loading }: any = useFetch('/playlists', token);

  return (
    <MainLayout>
      <PlaylistContainer title="All Playlists">
        {!loading ? (
          data && data.map((playlist: Playlist, idx: number) => (
            <PlaylistCard
              key={idx}
              image={playlist.cover}
              name={playlist.name}
              slug={playlist.slug}
            />
          ))
        ) : (
          [...Array(10)].map((card, idx) => (
            <PlaylistCardSkeleton key={idx} />
          ))
        )}
      </PlaylistContainer>
    </MainLayout>
  )
}

export default Playlists