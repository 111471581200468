import { Link, useLocation } from "react-router-dom";
import Sidebar from "../Components/Sidebar";
import { useDispatch } from 'react-redux';
import { clearAuth } from '../Store/authSlice';

import styles from "../Styles/MainLayout.module.css";

interface Props {
  children: any;
}

function MainLayout({ children }: Props) {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname.split("/")[1];

  const links: any = {
    "": "Home",
    playlists: "Playlists",
    search: "Search",
  };

  const logout = () => {
    dispatch(clearAuth());
    localStorage.removeItem("token");
  }

  return (
    <div>
      <div className={styles.flexContainer}>
        <Sidebar />
        <div className={styles.pageContent}>
          <header className={styles.header}>
            <h3 className={styles.title}>{links[pathname]}</h3>
            <div className={styles.links}>
              <button onClick={logout}>Logout</button>
            </div>
          </header>
          <div className={styles.albumsContainer}>{children}</div>
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
