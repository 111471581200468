import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Input from "../Components/Input";
import styles from "../Styles/Login.module.css";

import axios from "axios";
import { useDispatch } from "react-redux";
import { setAuth } from "../Store/authSlice";

function Register() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const [error, setError] = useState<String|null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const register = async (e: any) => {
    e.preventDefault();
    try {
      await axios.post('/users/register', {
        name,
        email,
        password
      });

      const { data } = await axios.post('/users/login', {
        email,
        password
      });

      const token = data.token;
      dispatch(setAuth({ token }));
      navigate('/');
    } catch (e: any) {
      setError(e.response.data.message);
      console.error(e);
    }
  }

  return (
    <div className={styles.loginPage}>
      <header>
        <h1>
          <Link to="/">MyZent</Link>
        </h1>
      </header>
      <div className={styles.pageBody}>
        <div className={styles.loginContainer}>
          <h1 className={styles.title}>Register</h1>
          {error && <div className={styles.error}>{error}</div>}
          <form onSubmit={register}>
            <div className={styles.inputs}>
              <Input name="Full Name" state={name} setState={setName} />
              <Input
                name="Email"
                type="email"
                state={email}
                setState={setEmail}
              />
              <Input
                name="Password"
                type="password"
                state={password}
                setState={setPassword}
              />
            </div>
            <button className={styles.submitButton}>Register</button>
            <div className={styles.link}>
              <p>Already have an account?</p>
              <Link to="/login">Click Here</Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Register;
