import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PlaylistState {
  playingPlaylist: string | null;
  songs: any;
}

// Initialize state from localStorage or use default state
const initialState: PlaylistState = {
  playingPlaylist: null,
  songs: null
};

export const PlaylistSlice = createSlice({
  name: 'playlist',
  initialState,
  reducers: {
    setPlayingPlaylist: (state, action: PayloadAction<{ playingPlaylist: any }>) => {
      state.playingPlaylist = action.payload.playingPlaylist;
    },
    setSongs: (state, action: PayloadAction<{ songs: any }>) => {
      state.songs = action.payload.songs;
    }
  },
});

export const { setPlayingPlaylist, setSongs } = PlaylistSlice.actions;

export default PlaylistSlice.reducer;
