import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from 'moment';

import MainLayout from "../Layouts/MainLayout";
import { MdOutlineNotStarted, MdPause, MdPlayArrow } from "../Config/icons";
import SongsContainer from "../Components/SongsContainer";
import Song from "../Components/Song";
import SongSkeleton from "../Components/SongSkeleton";
import useFetch from "../Hooks/useFetch";
import { RootState } from "../Store";
import { setPlayingPlaylist, setSongs } from "../Store/playlistSlice";

import styles from "../Styles/Playlist.module.css";
import { useEffect } from "react";

interface SongInterface {
  _id: string;
  cover: string;
  name: string;
  artistName: string;
  albumName: string;
  song: string;
  uploadedDate: string;
}

function Playlist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const token: any = useSelector((state: RootState) => state.auth.token);
  const playingPlaylist: any = useSelector((state: RootState) => state.playlist.playingPlaylist);
  const songs: any = useSelector((state: RootState) => state.playlist.songs);
  const url = `/playlists/${slug}`;
  const { data, error, loading: loading }: any = useFetch(url, token);

  useEffect(() => {
    if (error) navigate('/playlists')
  }, [error]);

  const play = () => {
    if (!songs) {
      if (data && data.songs) {
        dispatch(setSongs({ songs: data.songs }));
        dispatch(setPlayingPlaylist({ playingPlaylist: data.playlist._id }));
      }
      return;
    }

    dispatch(setPlayingPlaylist({ playingPlaylist: null }));
    dispatch(setSongs({ songs: null }))
  }

  return (
    <MainLayout>
      <section className={styles.playlistInfo}>
        {!loading ? (
          data && (
            <>
              <img src={data.playlist.cover} alt={data.playlist.name} />
              <div className={styles.text}>
                <p className={styles.mutedText}>Playlist</p>
                <h1 className={styles.name}>{data.playlist.name}</h1>
                <h4 className={styles.plays}>
                  <span className={styles.playsContainerIcon}>
                    <MdOutlineNotStarted />
                  </span>
                  <span className={styles.playsContainerText}>Playlist</span>
                </h4>
                {(data && (data.songs.length > 0)) && (
                  (playingPlaylist !== data.playlist._id) ? (
                    <div onClick={play} className={styles.playButton}>
                      <MdPlayArrow />
                      <span>Play</span>
                    </div>
                  ) : (
                    <div onClick={play} className={styles.playButton}>
                      <MdPause />
                      <span>Pause</span>
                    </div>
                  )
                )}
              </div>
            </>
          )
        ) : (
          <SkeletonTheme baseColor="#333333" highlightColor="#444">
            <Skeleton height={250} width={250} />
            <div className={styles.text}>
              <p style={{ marginBottom: "1em" }}>
                <Skeleton count={1} width={200} />
              </p>
              <p style={{ marginBottom: "1em" }}>
                <Skeleton count={1} width={300} />
              </p>
              <Skeleton count={1} width={150} />
            </div>
          </SkeletonTheme>
        )}
      </section>
      <section className={styles.songs}>
        <h1 className={styles.title}>Songs</h1>
        <SongsContainer>
          {!loading ? (
            data &&
              data.songs.map((song: SongInterface, idx: number) => (
                <>
                  <Song
                    key={idx}
                    id={song._id}
                    cover={song.cover}
                    name={song.name}
                    artist={song.artistName}
                    album={song.albumName}
                    added_date={moment(song.uploadedDate).fromNow()}
                  />
                </>
              ))
          ) : (
            [...Array(5)].map((_, idx: number) => (
              <SongSkeleton key={idx} />
            ))
          )}
          {(data && (data.songs.length === 0)) && (
            <p className={styles.error}>Playlist play time ended</p>
          )}
        </SongsContainer>
      </section>
    </MainLayout>
  );
}

export default Playlist;
