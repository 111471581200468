import React from 'react';

import styles from '../Styles/SongsContainer.module.css'

interface Props {
  children: any;
}

function SongsContainer({ children }: Props) {
  return (
    <div className={styles.songsContainer}>
      {children}
    </div>
  )
}

export default SongsContainer