import React from 'react'

import styles from "../Styles/Input.module.css";

interface Props {
  name: string;
  type?: string;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
}

function Input({ name, state, setState, type = "text" }: Props) {
  return (
    <div className={styles.formInput}>
      <label>{name}</label>
      <input
        value={state}
        onChange={(e) => setState(e.target.value)}
        type={type}
      />
    </div>
  );
}

export default Input;
