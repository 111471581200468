import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { BiSearchAlt } from "../Config/icons";
import { RootState } from "../Store";
import useFetch from "../Hooks/useFetch";

import MainLayout from "../Layouts/MainLayout";
import PlaylistContainer from "../Components/PlaylistContainer";
import PlaylistCard from "../Components/PlaylistCard";
import PlaylistCardSkeleton from "../Components/PlaylistCardSkeleton";

import styles from "../Styles/Search.module.css";

interface Playlist {
  cover: string;
  name: string;
  slug: string;
}

function Search() {
  const token: any = useSelector((state: RootState) => state.auth.token);
  const { data, error, loading }: any = useFetch('/playlists', token);
  
  const [search, setSearch] = useState("");
  const [playlists, setPlaylists] = useState<any>();

  useEffect(() => setPlaylists(data), [data, setPlaylists]);

  useEffect(() => {
    if (search.length > 0) {
      const filteredPlaylists = playlists?.filter((playlist: Playlist) => 
        playlist.name.toLowerCase().includes(search.toLowerCase())
      );

      setPlaylists(filteredPlaylists);
    } else {
      setPlaylists(data);
    }
  }, [search, playlists]);

  return (
    <MainLayout>
      <section className={styles.searchBar}>
        <input
          value={search}
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
          type="text"
        />
        <span className={styles.icon}>
          <BiSearchAlt />
        </span>
      </section>
      <PlaylistContainer>
        {!loading ? (
          playlists && playlists.map((playlist: Playlist, idx: number) => (
            <PlaylistCard
              key={idx}
              image={playlist.cover}
              name={playlist.name}
              slug={playlist.slug}
            />
          ))
        ) : (
          [...Array(10)].map((card, idx) => (
            <PlaylistCardSkeleton key={idx} />
          ))
        )}
      </PlaylistContainer>
    </MainLayout>
  );
}

export default Search;
