import React from 'react'

import { MdOutlineNotStarted } from 'react-icons/md'

import styles from '../Styles/PlaylistCard.module.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  image: string;
  name: string;
  slug: string;
}

function PlaylistCard({ image, name, slug }: Props) {
  const navigate = useNavigate();

  const openPlaylist = () => navigate(`/playlists/${slug}`)

  return (
    <div onClick={openPlaylist} className={styles.playlistCard}>
      <img src={image} alt={name} />
      <div className={styles.text}>
        <h3 className={styles.name}>{name}</h3>
        <p className={styles.plays}>
          <span className={styles.playsContainerIcon}><MdOutlineNotStarted /></span>
          <span className={styles.playsContainerText}>Playlist</span>
        </p>
      </div>
    </div>
  )
}

export default PlaylistCard