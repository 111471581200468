import React from 'react';

import styles from '../Styles/PlaylistContainer.module.css'

interface Props {
  title?: string;
  children: any;
}

function PlaylistContainer({ children, title }: Props) {
  return (
    <div>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={styles.playlistContainerContent}>
        {children}
      </div>
    </div>
  )
}

export default PlaylistContainer