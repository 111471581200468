import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import Home from './Pages/Home';
import Playlists from './Pages/Playlists';
import Search from './Pages/Search';
import Playlist from './Pages/Playlist';
import Login from './Pages/Login';
import Register from './Pages/Register';

import './App.css';
import PrivateRoute from './Components/PrivateRoute';
import { useSelector } from 'react-redux';
import { RootState } from './Store';
import Footer from './Components/Footer';

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://musicbackend.myzent.com/api' : 'http://localhost:8080/api';

function App() {
  const songs: any = useSelector((state: RootState) => state.playlist.songs);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<Home />} />
          <Route path="/playlists">
            <Route index element={<Playlists />} />
            <Route path=':slug' element={<Playlist />} />
          </Route>
          <Route path="/search" element={<Search />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
      {songs && <Footer />}
    </BrowserRouter>
  );
}

export default App;
